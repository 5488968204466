import store from "../store";
import router from "../router";
import * as Sentry from "@sentry/vue";
import { auth, getFunctions, httpsCallable } from "@/main";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  // signInWithRedirect,
  signOut,
  signInWithPopup,
  getAuth,
} from "firebase/auth";
import { IUser } from "@/interface/interface";
import UserService from "./user-service";
import StripeService from "./stripe-service";
import { HelperFunctions } from "@/helpers/helpers";
import { increment } from "firebase/firestore";
import NotificationAlertsService from "./notification-alerts-service";
import NotificationService from "./notification-service";
import dayjs from "dayjs";

const userService = new UserService();
const stripeService = new StripeService();
const notificationAlertsService = new NotificationAlertsService();
const notificationService = new NotificationService();

export default class AuthService {
  async handleStripeRedirect(account: any) {
    try {
      const functions = getFunctions();
      const handleStripeRedirect = httpsCallable(
        functions,
        "stripeFunctions-handleStripeRedirect"
      );
      const result: any = await handleStripeRedirect(account);
      return result.data;
    } catch (error: any) {
      console.error(error);
    }
  }

  async createRefund(refund: any) {
    try {
      const functions = getFunctions();
      const createRefund = httpsCallable(
        functions,
        "stripeFunctions-createRefund"
      );
      const result = await createRefund(refund);
      return result;
    } catch (error) {
      console.error(error, "error with refund");
    }
  }

  async createPaymentIntent(payment: any) {
    try {
      const functions = getFunctions();
      const createPaymentIntent = httpsCallable(
        functions,
        "stripeFunctions-createPaymentIntent"
      );
      const result = await createPaymentIntent(payment);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      console.log("payment intend created");
    }
  }

  // async createStripeAccount(user: IUser) {
  //   try {
  //     const functions = getFunctions();
  //     const createAccount = httpsCallable(
  //       functions,
  //       "stripeFunctions-createStripeAccount"
  //     );
  //     const result: any = await createAccount(user);
  //     return result.data;
  //   } catch (error: any) {
  //     console.error(error);
  //     store.commit("SET_ALERT", {
  //       title: "Stripe account create",
  //       alert: "There was a problem with creating your stripe account",
  //       type: "error",
  //     });
  //   }
  // }

  createUserWithEmailAndPassword(user: IUser & { password: string }) {
    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then(async (userCreated) => {
        const auth: any = getAuth();
        sendEmailVerification(auth.currentUser);
        // TODO: Avatar...
        const userProfile: IUser = {
          avatar: "",
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          role: "user",
          createdTime: dayjs().format("DD-MM-YYYY HH:mm:ss"),
          created_unix: Date.now(),
          lastSignInTime: userCreated.user.metadata.lastSignInTime,
          uid: userCreated.user.uid,
          id: HelperFunctions.generateNewId(),
          bio: "",
          interests: [],
          // location: {},
          // bookings: [],
          favourites: [],
          // bookings_history: [],
          // purchases: [],
          // purchases_history: [],
          account_id: "",
        };
        userService.saveUser(userProfile).then((user: IUser) => {
          console.log("user ====>", user);
          stripeService.createStripeAccount(userProfile);
          // notificationAlertsService.getNotificationAlerts().then((alerts) => {
          //   console.log(alerts, "alerts");
          //   notificationAlertsService.createNotificationAlert({
          //     id: user.id,
          //     created_unix: Date.now(),
          //     notificationAlerts: alerts,
          //   });
          // });
          notificationAlertsService.createNotificationAlert({
            id: user.id,
            created_unix: Date.now(),
            notificationAlerts:
              notificationAlertsService.rawNotificationAlerts(),
          });
        });

        return userCreated;
      })
      .catch((err) => {
        console.error(err);
        store.commit("SET_ALERT", {
          title: "Sign Up Error",
          alert: err.message || "Something went wrong",
          type: "error",
        });
      })
      .finally(async () => {
        if (store.state.user !== null) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          router.push("/email-confirmation");
        }
      });
  }

  async signInWithEmailAndPassword(user: { email: string; password: string }) {
    signInWithEmailAndPassword(auth, user.email, user.password)
      .then(async (userCredential) => {
        if (userCredential.user.emailVerified) {
          try {
            userService
              .getUserByEmail(user.email)
              .then((user: any) => {
                if (user === null) {
                  return;
                } else {
                  notificationAlertsService
                    .getNotificationAlerts()
                    .then((data: any) => {
                      const alerts = data.filter(
                        (alert: any) => alert.id === user.id
                      );

                      if (alerts.length === 0) {
                        notificationAlertsService
                          .createNotificationAlert({
                            id: user.id,
                            created_unix: Date.now(),
                            notificationAlerts:
                              notificationAlertsService.rawNotificationAlerts(),
                          })
                          .then((alerts) =>
                            console.log("Notification alerts created:", alerts)
                          )
                          .catch((error) =>
                            console.error(
                              "Error creating notification alert:",
                              error
                            )
                          );
                      }
                    });
                  userService.updateUser({
                    ...user,
                    visitCount: increment(1),
                    lastSignInTime: HelperFunctions.formatUnixDate(Date.now()),
                    uid: user.uid,
                    id: user.id,
                  });

                  store.commit("SET_USER", {
                    ...user,
                    lastSignInTime: user.lastSignInTime,
                    uid: user.uid,
                    id: user.id,
                  });
                  store.commit("SET_MASTER_USER", {
                    ...userCredential.user,
                    role: user.role,
                    user_id: user.id,
                  });

                  store.commit("SET_AUTH", {
                    role: user.role,
                    user_id: user.id,
                  });
                  store.commit("SET_LOGGED_IN", true);
                  store.commit("SET_LOADING", false);
                  return {
                    ...user,
                    lastSignInTime: userCredential.user.metadata.lastSignInTime,
                    uid: userCredential.user.uid,
                    id: user.id,
                  };
                }
              })
              .then((user: any) => {
                router.push("/dashboard/" + user.id);
              })
              .catch((error) => {
                store.commit("SET_ALERT", {
                  title: "Sign In Error",
                  alert: error.message || "Something went wrong",
                  type: "error",
                });
                console.error(error);
                Sentry.captureException(error);
              });
          } catch (error) {
            console.error(error);
          }
        } else {
          try {
            const unconfirmedUser = await userService.getUserByEmail(
              user.email
            );
            store.commit("SET_MASTER_USER", {
              ...userCredential.user,
              role: unconfirmedUser?.role,
              user_id: unconfirmedUser?.id,
            });
          } catch (error) {
            console.error(error);
          } finally {
            router.push("/email-confirmation");
          }
        }
      })
      .catch((error) => {
        store.commit("SET_ALERT", {
          title: "Sign In Error",
          alert: error.message || "Something went wrong",
          type: "error",
        });
      });
  }

  signInWithGoogle() {
    // store.commit("SET_LOADING", true);
    signInWithPopup(auth, new GoogleAuthProvider()).then((result) => {
      const user: any = result.user;
      console.log("Sign-in successful:", user);
      userService.getUserByEmail(user.email).then((savedUser) => {
        console.log(savedUser, "savedUser");
        if (savedUser) {
          store.commit("SET_USER", savedUser);
          store.commit("SET_LOGGED_IN", true);
          store.commit("SET_LOADING", false);
          router.push(`/dashboard/${savedUser.id}`);
        } else {
          const userProfile: IUser = {
            avatar: "",
            role: "user",
            firstname: user.displayName.split(" ")[0],
            lastname: user.displayName.split(" ")[1],
            email: user.email,
            createdTime: user.metadata.creationTime,
            created_unix: Date.now(),
            lastSignInTime: user.metadata.lastSignInTime,
            uid: user.uid,
            id: HelperFunctions.generateNewId(),
            bio: "",
            interests: [],
            // location: {},
            favourites: [],
            stripe_account_linked: false,
            // purchases: [],
            // purchases_history: [],
            account_id: "",
          };
          stripeService.createStripeAccount(userProfile);
          userService
            .saveUser(userProfile)
            .then((user) => {
              router.push(`/profile/${user.id}`);
              store.commit("SET_ALERT", {
                title: "User Created",
                alert: "User created successfully",
                type: "success",
              });
            })
            .catch((error) => {
              store.commit("SET_ALERT", {
                title: "Create User Error",
                alert: error.message || "Something went wrong",
                type: "error",
              });
            });
        }
      });
    });
  }

  signOut() {
    signOut(auth)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console.log("SIGING OUT");
        router.push("/").catch((err) => {
          console.error(err);
        });
        store.commit("SET_LOGGED_IN", false);
        store.commit("SET_USER", null);
        store.commit("SET_OWNER", null);
        store.commit("SET_LOADING", false);
        store.commit("SET_USER_ITEMS", []);
        store.commit("SET_ALL_ITEMS", []);
        store.commit("SET_MASTER_USER", {});
        store.commit("SET_MESSAGES", []);
        store.commit("SET_BOOKINGS", []);
        store.commit("SET_NOTIFICATIONS", []);
        store.commit("SET_BOOKINGS_HISTORY", []);
        store.commit("SET_ITEM", {});
        store.commit("SET_AUTH", {});
        store.commit("SET_MESSAGE_COUNT", 0);
        store.commit("SET_NOTIFICATION_PANEL_OPEN", false);
        store.commit("SET_PROFILE_PANEL_OPEN", false);
        store.commit("SET_LOCATION_COORDINATES", {});
        store.commit("SET_LOCATION", {});
        store.commit("SET_USERS", []);
        localStorage.clear();
      })
      .catch((error) => {
        store.commit("SET_ALERT", {
          title: "Sign Out Error",
          alert: error.message || "Something went wrong",
          type: "error",
        });
      });
  }
}
