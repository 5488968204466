import store from "@/store";
import { ref, onValue, push, set, update } from "firebase/database";

import {
  collection,
  database,
  db,
  getDocs,
  where,
  query,
  updateDoc,
  doc,
} from "@/main";
import { DocumentData, deleteDoc, setDoc } from "firebase/firestore";

const alerts = [
  {
    display: true,
    name: "User profile deleted",
    value: "user_profile_deleted",
    description: "Accept to receive an alert when your user profile is deleted",
    accepted: true,
  },
  {
    display: true,
    name: "Item deleted",
    value: "item_deleted",
    description: "Accept to receive an alert when your item is deleted",
    accepted: true,
  },
  {
    display: true,
    name: "Item updated",
    value: "item_updated",
    description: "Accept to receive an alert when your item is updated",
    accepted: true,
  },
  {
    display: true,
    name: "Item uploaded",
    value: "item_uploaded",
    description: "Accept to receive an alert when your item is uploaded",
    accepted: true,
  },
  {
    display: true,
    name: "Booking request",
    value: "booking_request",
    description:
      "Accept to receive an alert when you receive a booking request",
    accepted: true,
  },
  {
    display: true,
    name: "Booking request made",
    value: "booking_request_made",
    description: "Accept to receive an alert when you make a booking request",
    accepted: true,
  },
  {
    display: true,
    name: "Booking request paid",
    value: "booking_request_paid",
    description: "Accept to receive an alert when you pay a booking request",
    accepted: true,
  },
  {
    display: true,
    name: "Booking payment received",
    value: "booking_payment_received",
    description:
      "Accept to receive an alert when you receive a booking payment",
    accepted: true,
  },
  {
    display: true,
    name: "Booking reminder",
    value: "booking_reminder",
    description:
      "Accept to receive an alert to remind you that you have a pending booking that hasn't been approved",
    accepted: true,
  },
  {
    display: true,
    name: "Booking about to start",
    value: "booking_start",
    description: "Accept to receive an alert when a booking is about to start",
    accepted: true,
  },
  {
    display: true,
    name: "Booking about to end",
    value: "booking_end",
    description: "Accept to receive an alert when a booking is about to end",
    accepted: true,
  },
  {
    display: true,
    name: "Review received",
    value: "review_received",
    description: "Accept to receive an alert when you receive a review",
    accepted: true,
  },
  {
    display: true,
    name: "Review made",
    value: "review_made",
    description: "Accept to receive an alert when you make a review",
    accepted: true,
  },
  {
    display: true,
    name: "Payment reminder",
    value: "pending_payment",
    description: "Accept to receive an alert when you have a pending payment",
    accepted: true,
  },
  {
    display: true,
    name: "Newsletter alert",
    value: "newsletters_accepted",
    description: "Accept to receive newsletters from the site",
    accepted: true,
  },
  {
    display: true,
    name: "Message received alert",
    value: "message_received_alert",
    description: "Accept to receive an alert when you have a new message",
    accepted: true,
  },
  {
    display: true,
    name: "Message sent alert",
    value: "message_sent_alert",
    description: "Accept to receive an alert when you have sent a message",
    accepted: true,
  },
  {
    display: true,
    name: "Offer accepted",
    value: "offer_accepted",
    description: "Accept to receive an alert when your offer is accepted",
    accepted: true,
  },
  {
    display: true,
    name: "Request accepted",
    value: "request_accepted",
    description: "Accept to receive an alert when your request is accepted",
    accepted: true,
  },
  {
    display: true,
    name: "Feedback received",
    value: "feedback_received",
    description: "Accept to receive an alert when you receive feedback",
    accepted: true,
  },
  {
    display: true,
    name: "Forgotten order",
    value: "forgotten_order",
    description:
      "Accept to receive an alert when you have forgotten to confirm an order",
    accepted: true,
  },
  {
    display: true,
    name: "Forgotten order complete",
    value: "forgotten_order_complete",
    description:
      "Accept to receive an alert when you have forgotten to confirm an order as complete",
    accepted: true,
  },
  {
    display: true,
    name: "Payment received",
    value: "payment_received",
    description: "Accept to receive an alert when you have received payment",
    accepted: true,
  },
  {
    display: true,
    name: "New listing",
    value: "new_listing",
    description:
      "Accept to receive an alert when someone you follow posts a new listing",
    accepted: true,
  },
  {
    display: true,
    name: "New comment",
    value: "new_comment",
    description:
      "Accept to receive an alert when a listing public discussion you follow has a new comment",
    accepted: true,
  },
  {
    display: true,
    name: "Listing updated",
    value: "listing_updated",
    description:
      "Accept to receive an alert when a listing you have commented on has been updated",
    accepted: true,
  },
];

// on user sign up create notifications
// on user login if no notifications create notifications

export default class NotificationAlertsService {
  async getNotificationAlertById(id: string) {
    const alertsRef = collection(
      db,
      `notification_alerts_${process.env.VUE_APP_ENV}`
    );
    const q = query(alertsRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    store.commit(
      "SET_NOTIFICATION_ALERTS",
      querySnapshot.docs.map((doc) => doc.data())[0]
    );
    return querySnapshot.docs.map((doc) => doc.data())[0];
  }

  returnAllAlerts() {
    return alerts;
  }

  async getNotificationAlerts(): Promise<DocumentData[]> {
    const alertsRef = collection(db, `notification_alerts_dev`);

    const querySnapshot = await getDocs(alertsRef);
    const alerts = querySnapshot.docs.map((doc) => doc.data());
    console.log(alerts, "====<");
    return alerts;
  }

  async createNotificationAlert(alert: any) {
    // console.log(alert, "alert");
    // await setDoc(
    //   doc(db, `notification_alerts_${process.env.VUE_APP_ENV}`, alert.id),
    //   alert
    // );

    // const alertsRef = collection(
    //   db,
    //   `notification_alerts_${process.env.VUE_APP_ENV}`
    // );
    // const querySnapshot = await getDocs(alertsRef);
    // const alerts = querySnapshot.docs.map((doc) => doc.data());
    // const userAlerts = alerts.find((a: any) => a.id === store.state.user?.id);
    // store.commit("SET_NOTIFICATION_ALERTS", userAlerts);
    // return alerts;
    try {
      // Set the document in the specified collection
      await setDoc(
        doc(db, `notification_alerts_${process.env.VUE_APP_ENV}`, alert.id),
        alert
      );

      // Get the collection reference
      const alertsRef = collection(
        db,
        `notification_alerts_${process.env.VUE_APP_ENV}`
      );

      // Get all documents in the collection
      const querySnapshot = await getDocs(alertsRef);

      // Map the query snapshot to an array of alert objects
      const alerts = querySnapshot.docs.map((doc) => doc.data());

      // Find the user's alert by ID
      const userAlert = alerts.find((a) => a.id === store.state.user?.id);

      // Commit the user's alert to the store
      store.commit("SET_NOTIFICATION_ALERTS", userAlert);

      // Return the array of alerts
      return alerts;
    } catch (error) {
      console.error("Error creating notification alert:", error);
      throw error; // Throw the error for handling in the calling code
    }
  }

  rawNotificationAlerts() {
    return [
      {
        name: "User profile deleted",
        description:
          "Accept to receive an alert when your user profile is deleted",
        accepted: true,
        value: "user_profile_deleted",
        display: true,
      },
      {
        value: "item_deleted",
        display: true,
        description: "Accept to receive an alert when your item is deleted",
        accepted: true,
        name: "Item deleted",
      },
      {
        display: true,
        accepted: true,
        value: "item_updated",
        description: "Accept to receive an alert when your item is updated",
        name: "Item updated",
      },
      {
        description: "Accept to receive an alert when your item is uploaded",
        value: "item_uploaded",
        name: "Item uploaded",
        accepted: true,
        display: true,
      },
      {
        accepted: true,
        value: "booking_request",
        description:
          "Accept to receive an alert when you receive a booking request",
        display: true,
        name: "Booking request",
      },
      {
        name: "Booking request made",
        display: true,
        description:
          "Accept to receive an alert when you make a booking request",
        value: "booking_request_made",
        accepted: true,
      },
      {
        description:
          "Accept to receive an alert when you pay a booking request",
        accepted: true,
        name: "Booking request paid",
        value: "booking_request_paid",
        display: true,
      },
      {
        accepted: true,
        value: "booking_payment_received",
        display: true,
        description:
          "Accept to receive an alert when you receive a booking payment",
        name: "Booking payment received",
      },
      {
        accepted: true,
        name: "Booking reminder",
        display: true,
        value: "booking_reminder",
        description:
          "Accept to receive an alert to remind you that you have a pending booking that hasn't been approved",
      },
      {
        accepted: true,
        name: "Booking about to start",
        description:
          "Accept to receive an alert when a booking is about to start",
        value: "booking_start",
        display: true,
      },
      {
        value: "booking_end",
        description:
          "Accept to receive an alert when a booking is about to end",
        accepted: true,
        name: "Booking about to end",
        display: true,
      },
      {
        value: "review_received",
        description: "Accept to receive an alert when you receive a review",
        name: "Review received",
        accepted: true,
        display: true,
      },
      {
        value: "review_made",
        accepted: true,
        description: "Accept to receive an alert when you make a review",
        name: "Review made",
        display: true,
      },
      {
        description:
          "Accept to receive an alert when you have a pending payment",
        name: "Payment reminder",
        display: true,
        value: "pending_payment",
        accepted: true,
      },
      {
        display: true,
        accepted: true,
        value: "newsletters_accepted",
        name: "Newsletter alert",
        description: "Accept to receive newsletters from the site",
      },
      {
        value: "message_received_alert",
        display: true,
        description: "Accept to receive an alert when you have a new message",
        name: "Message received alert",
        accepted: true,
      },
      {
        display: true,
        accepted: true,
        name: "Message sent alert",
        description: "Accept to receive an alert when you have sent a message",
        value: "message_sent_alert",
      },
      {
        accepted: true,
        name: "Offer accepted",
        value: "offer_accepted",
        display: true,
        description: "Accept to receive an alert when your offer is accepted",
      },
      {
        description: "Accept to receive an alert when your request is accepted",
        name: "Request accepted",
        accepted: true,
        value: "request_accepted",
        display: true,
      },
      {
        description: "Accept to receive an alert when you receive feedback",
        accepted: true,
        display: true,
        value: "feedback_received",
        name: "Feedback received",
      },
      {
        name: "Forgotten order",
        description:
          "Accept to receive an alert when you have forgotten to confirm an order",
        display: true,
        accepted: true,
        value: "forgotten_order",
      },
      {
        name: "Forgotten order complete",
        display: true,
        value: "forgotten_order_complete",
        description:
          "Accept to receive an alert when you have forgotten to confirm an order as complete",
        accepted: true,
      },
      {
        display: true,
        value: "payment_received",
        accepted: true,
        name: "Payment received",
        description:
          "Accept to receive an alert when you have received payment",
      },
      {
        accepted: true,
        value: "new_listing",
        description:
          "Accept to receive an alert when someone you follow posts a new listing",
        display: true,
        name: "New listing",
      },
      {
        display: true,
        name: "New comment",
        value: "new_comment",
        accepted: true,
        description:
          "Accept to receive an alert when a listing public discussion you follow has a new comment",
      },
      {
        name: "Listing updated",
        description:
          "Accept to receive an alert when a listing you have commented on has been updated",
        accepted: true,
        display: true,
        value: "listing_updated",
      },
    ];
  }

  async updateNotificationAlerts(alert: any): Promise<any> {
    const alertRef = doc(
      db,
      `notification_alerts_${process.env.VUE_APP_ENV}`,
      alert.id
    );
    await updateDoc(alertRef, alert);
    const alertsRef = collection(
      db,
      `notification_alerts_${process.env.VUE_APP_ENV}`
    );
    const querySnapshot = await getDocs(alertsRef);
    const alerts = querySnapshot.docs.map((doc) => doc.data());
    const userAlerts = alerts.find((a: any) => a.id === alert.id);
    // if (userAlerts?.notificationAlerts.length) {
    store.commit("SET_NOTIFICATION_ALERTS", userAlerts);
    // }
    return alerts;
  }

  // async deleteNotificationAlerts(userId: string) {
  // const alertRef = collection(
  //   db,
  //   `notification_alerts_${process.env.VUE_APP_ENV}`
  // );
  // const q = query(alertRef, where("id", "==", userId));
  // const querySnapshot = await getDocs(q);
  // const alert = querySnapshot.docs.map((doc) => doc.data())[0];
  // const alertDoc = doc(
  //   db,
  //   `notification_alerts_${process.env.VUE_APP_ENV}`,
  //   alert.id
  // );
  // await deleteDoc(alertDoc);
  // }
}
