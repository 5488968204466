// import store from "../store";
// import router from "../router";
// auth
import { IUser } from "@/interface/interface";
import { getFunctions, httpsCallable } from "@/main";

export default class StripeService {
  async listCharges(customerId: string) {
    try {
      const functions = getFunctions();
      const listCharges = httpsCallable(
        functions,
        "stripeFunctions-listCharges"
      );
      const result = await listCharges({ customerId });
      return result;
    } catch (error) {
      console.error(error, "error with get list charges");
    }
  }

  async getPaymentItent(payment_intent_id: string) {
    try {
      const functions = getFunctions();
      const getPaymentIntent = httpsCallable(
        functions,
        "stripeFunctions-getPaymentIntent"
      );
      const result = await getPaymentIntent({ payment_intent_id });
      return result;
    } catch (error) {
      console.error(error, "error with get payment intent");
    }
  }

  async createStripeAccount(user: IUser) {
    try {
      const functions = getFunctions();
      const createAccount = httpsCallable(
        functions,
        "stripeFunctions-createStripeAccount"
      );
      const result = await createAccount(user);
      return result.data;
    } catch (error: any) {
      console.error(error);
      // throw new Error(error.message);
    } finally {
      console.log("done");
    }
  }

  async createLoginLink(account_id: string) {
    try {
      const functions = getFunctions();
      const createLoginLink = httpsCallable(
        functions,
        "stripeFunctions-createLoginLink"
      );
      const result = await createLoginLink({ account_id });
      return result;
    } catch (error) {
      console.error(error, "error with create login link");
    }
  }

  async getStripeAccount(user_id: string, accountId: string) {
    try {
      const functions = getFunctions();
      const getStripeAccount = httpsCallable(
        functions,
        "stripeFunctions-getStripeAccount"
      );
      const result = await getStripeAccount({
        user_id: user_id,
        account_id: accountId,
      });
      return result;
    } catch (error) {
      console.error(error, "error with get stripe account");
    }
  }
}
