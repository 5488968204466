
import { Vue, Component } from "vue-property-decorator";
import { ref, push, set } from "firebase/database";

import { database } from "@/main";

@Component({})
export default class CookieComponent extends Vue {
  consentGiven = false;
  defaultConsent = true;
  anonymousUserId = "";
  dialog = false;

  async setDbData(): Promise<void> {
    const userConsentRef = push(ref(database, "userConsent"));
    const userConsentId = userConsentRef.key;

    await set(userConsentRef, {
      id: userConsentId,
      userId: this.anonymousUserId,
      consentGiven: this.defaultConsent,
      created: new Date().toISOString(),
    });
  }

  acceptCookies() {
    this.setCookie("_tenshare_consent", "accepted", 365);
    // Set the consent in Firebase Realtime Database
    this.setDbData();
    this.consentGiven = true;
  }

  consentChanged(event: Event): void {
    event.preventDefault();
  }

  setCookie(name: string, value: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
  }

  accept(): void {
    this.dialog = false;
    this.setCookie(
      "_tentshare_consent",
      this.defaultConsent ? "accepted" : "",
      365
    );
    this.consentGiven = this.defaultConsent;
    this.setDbData();
  }

  generateAnonymousUserId() {
    // Generate a random anonymous user ID
    return Math.random().toString(36).substring(2);
  }

  showCookieSettings(): void {
    this.dialog = true;
  }

  closeModal(): void {
    this.dialog = false;
  }

  getCookie() {
    const cookies = document.cookie.split(";");
    const cookie = cookies.find((cookie) =>
      cookie.includes("_tenshare_consent")
    );
    return cookie ? cookie.split("=")[1] : "";
  }

  created(): void {
    if (!this.anonymousUserId) {
      this.anonymousUserId = this.generateAnonymousUserId();
      this.setCookie("_tentshare_accept_id", this.anonymousUserId, 365);
    }
    const consent = this.getCookie();
    if (consent === "accepted") {
      this.consentGiven = true;
      this.defaultConsent = this.consentGiven;
    }
  }
}
