import { IEvent, IUser } from "@/interface/interface";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const initiateState: () => {
  auth: {
    user_id: string;
  };
  users: [];
  user: IUser;
  owner: any;
  master: any;
  loggedIn: boolean;
  events: IEvent[];
  loading: boolean;
  alert: object | null;
  userItems: object;
  allItems: [];
  sports: [];
  stripe_url: string;
  messages: [];
  message_count: number;
  booking: object | null;
  amount_paid: number;
  bookings: [];
  notifications: [];
  bookings_history: [];
  location: object;
  isFestivalItem: boolean;
  showFavorites: boolean;
  item: {
    owner: string;
    location: object;
    location_name: string;
    tags: Array<object>;
  };
  search_criteria: object;
  inbox_route: object;
  notificationPanelOpen: boolean;
  profilePanelOpen: boolean;
  item_highlighted: object;
  notificationAlerts: [];
  timeLineItems: [];
} = () => ({
  auth: {
    user_id: "",
  },
  master: {},
  users: [],
  user: {
    id: "",
    firstname: "",
    lastname: "",
    role: "",
    createdTime: "",
    lastSignInTime: "",
    account_id: "",
    email: "",
    visitCount: 0,
    uid: "",
    avatar: "",
    bio: "",
    interests: null,
    // location: {},
    // location_name: "",
    favourites: [],
  },
  owner: {},
  userItems: [],
  allItems: [],
  sports: [],
  messages: [],
  message_count: 0,
  booking: {},
  notifications: [],
  amount_paid: 0,
  bookings: [],
  bookings_history: [],
  location: {},
  isFestivalItem: false,
  showFavorites: false,
  item: {
    owner: "",
    location: {
      lat: String,
      lng: String,
      name: String,
    },
    tags: [],
    location_name: "",
    available: true,
  },
  stripe_url: "",
  events: [],
  loggedIn: false,
  loading: false,
  alert: null,
  search_criteria: {},
  inbox_route: {},
  notificationPanelOpen: false,
  profilePanelOpen: false,
  item_highlighted: {
    position: null,
  },
  notificationAlerts: [],
  timeLineItems: [],
});

export default new Vuex.Store({
  state: initiateState,
  getters: {
    user(state) {
      return state.user;
    },
    item(state) {
      return state.item;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value;
    },
    SET_MASTER_USER(state, master) {
      state.master = master;
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },
    SET_NOTIFICATION_PANEL_OPEN(state, value) {
      state.notificationPanelOpen = value;
    },
    SET_PROFILE_PANEL_OPEN(state, value) {
      state.profilePanelOpen = value;
    },
    SET_NOTIFICATION_ALERTS(state, alerts) {
      state.notificationAlerts = alerts;
    },
    SET_INBOX_ROUTE(state, route) {
      state.inbox_route = route;
    },
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_LOCATION_NAME(state, locationName) {
      state.item.location_name = locationName;
    },
    SET_LOCATION(state, location) {
      console.log(location, "location");
      state.item.location = location;
      state.location = location;
    },
    SET_LOCATION_COORDINATES(state, coordinates) {
      state.location = coordinates;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages;
    },
    SET_MESSAGE_COUNT(state, message_count) {
      state.message_count = message_count;
    },
    SET_SEARCH_CRITERIA(state, criteria) {
      state.search_criteria = criteria;
    },
    SET_FAVORITES(state, value) {
      state.showFavorites = value;
    },
    SET_OWNER(state, owner) {
      state.owner = owner;
    },
    SET_FAVOURITES(state, item) {
      state.user.favourites.push(item);
    },
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_TIMELINE_ITEMS(state, items) {
      state.timeLineItems = items;
    },
    REMOVE_FAVOURITE(state, id) {
      const items = state.user.favourites.filter(
        (favourite: { id: string; name: string; owner: string }) => {
          return favourite.id !== id;
        }
      );
      state.user.favourites = items;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_USER_ITEMS(state, items) {
      state.userItems = items;
    },
    SET_ALL_ITEMS(state, items) {
      state.allItems = items;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_STRIPE_URL(state, value) {
      state.stripe_url = value;
    },
    SET_BOOKING(state, booking) {
      state.booking = booking;
    },
    SET_BOOKINGS(state, bookings) {
      state.bookings = bookings;
    },
    SET_BOOKINGS_HISTORY(state, bookings_history) {
      state.bookings_history = bookings_history;
    },
    SET_ALERT(state, value) {
      state.alert = value;
    },
    SET_AMOUNT_PAID(state, value) {
      state.amount_paid = value;
    },
    SET_SPORTS(state, value) {
      state.sports = value;
    },
    SET_ITEM_HIGHLIGHTED(state, value) {
      state.item_highlighted = value;
    },
    SET_IS_FESTIVAL_ITEM(state, value) {
      state.isFestivalItem = value;
    },
  },
  actions: {},
  modules: {},
});
