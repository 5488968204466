import store from "../store";
import { ref, onValue, push, set, update } from "firebase/database";

import { database } from "@/main";
import { INotification } from "@/interface/interface";

export default class NotificationService {
  // the notifications are a combination of messages, new requests, reviews
  async getAllNotifications(userId: string, notificationViewed: boolean) {
    const notificationsRef = ref(database, "notifications");
    let notifications: INotification[] = [];
    store.commit("SET_LOADING", true);

    onValue(notificationsRef, (snapshot) => {
      if (snapshot.exists()) {
        notifications = []; // Reset the array before iterating over the snapshot values
        Object.values(snapshot.val()).forEach((element: any) => {
          if (notificationViewed) {
            if (element?.userId === store.state.user?.id) {
              notifications.push(element);
            }
          } else {
            if (element?.userId === store.state.user?.id && !element?.viewed) {
              notifications.push(element);
            }
          }
        });
        notifications = notifications.filter(
          (notification: INotification) => notification.userId === userId
        );
        store.commit("SET_NOTIFICATIONS", notifications);
        store.commit("SET_LOADING", false);
        return notifications;
      } else {
        store.commit("SET_NOTIFICATIONS", []); // Reset the notifications array if no snapshot exists
        return [];
      }
    });
  }

  async createNotification(notification: INotification) {
    const newNotificationRef = push(ref(database, "notifications"));
    const notificationId = newNotificationRef.key;

    await set(newNotificationRef, {
      ...notification,
      id: notificationId,
    });
  }

  async updateNotification(notification: INotification) {
    const updates: any = {};
    updates["/notifications/" + notification.id] = {
      ...notification,
      viewed: true,
    };
    update(ref(database), updates);
  }
}
