import store from "../store";
import { ref, onValue } from "firebase/database";
import { database } from "@/main";
import { db, setDoc, doc } from "../main";

import { IMessage } from "@/interface/interface";
import { HelperFunctions } from "@/helpers/helpers";

export default class MessageService {
  async getMessagesForAdmin() {
    const messagesRef = ref(database, "messages");
    let messages: any = [];
    store.commit("SET_LOADING", true);

    onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        messages = []; // Reset the array before iterating over the snapshot values
        Object.values(snapshot.val()).forEach((element: any) => {
          messages.push(element);
        });
        store.commit("SET_MESSAGES", messages);
        store.commit("SET_LOADING", false);
        return messages;
      } else {
        store.commit("SET_LOADING", false);
        store.commit("SET_MESSAGES", []); // Reset the messages array if no snapshot exists
        return [];
      }
    });
  }
  async getAllMessages() {
    const messagesRef = ref(database, "messages");
    let messages: any = [];
    store.commit("SET_LOADING", true);

    onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        messages = []; // Reset the array before iterating over the snapshot values
        Object.values(snapshot.val()).forEach((element: any) => {
          // VERY IMPORTANT!!!
          // TODO: check that this conditional definitely only displays messages for specific user
          if (
            element.senderId === store.state.user.id ||
            element.receiverId === store.state.user.id
          ) {
            messages.push(element);
          }
        });

        // const userMessages = messages.filter((message: any) => {
        //   return message.receiverId === store.state.user.id;
        // });
        store.commit("SET_MESSAGES", messages);
        store.commit("SET_LOADING", false);
        return messages;
      } else {
        store.commit("SET_LOADING", false);
        store.commit("SET_MESSAGES", []); // Reset the messages array if no snapshot exists
        return [];
      }
    });
  }

  async getMessageCount() {
    const messagesRef = ref(database, "messages");
    let messages: any = [];

    onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        messages = []; // Reset the array before iterating over the snapshot values
        Object.values(snapshot.val()).forEach((element: any) => {
          if (
            element.senderId === store.state.user.id ||
            element.receiverId === store.state.user.id
          ) {
            messages.push(element);
          }
        });
        const userMessages = messages.filter((message: any) => {
          return message.receiverId === store.state.user.id;
        });
        store.commit("SET_MESSAGE_COUNT", userMessages.length);
      } else {
        store.commit("SET_MESSAGE_COUNT", 0); // Reset the messages array if no snapshot exists
        console.log("no messages because no snapshot");
      }
    });
  }

  async createMessage(
    message: IMessage & {
      item: string;
      ownerEmail: string;
      ownerName: string;
      senderEmail: string;
      senderName: string;
    }
  ) {
    const messageRef = doc(db, "messages", HelperFunctions.generateNewId());
    await setDoc(messageRef, message, { merge: true });
  }
}
