
import * as Sentry from "@sentry/vue";
import { Vue, Component, Watch } from "vue-property-decorator";
import { onAuthStateChanged, User } from "firebase/auth";
import config, { AppConfig } from "@/configs/config-tentshare";
import update from "./mixins/update";
import { auth } from "@/main";
import { HelperFunctions } from "@/helpers/helpers";
import UserService from "./services/user-service";
import ItemService from "./services/item-service";
import AuthService from "./services/auth-service";
import MessageService from "./services/messaging-service";
import NotificationService from "./services/notification-service";

import MainNav from "@/components/navigation/main-nav.vue";
import AlertComponent from "./components/generic/alerts-component.vue";
import FooterComponent from "./components/generic/footer-component.vue";
import BackToTopComponent from "./components/generic/back-to-top-component.vue";
import CookieComponent from "./components/cookie-component.vue";

import SportService from "@/services/sports-service";

@Component({
  components: {
    MainNav,
    AlertComponent,
    FooterComponent,
    BackToTopComponent,
    CookieComponent,
  },
  mixins: [update],
  metaInfo: {
    title: "Tentshare",
    titleTemplate: "%s | Own Less. Adventure More",
    meta: [
      {
        name: "description",
        content: "Tenshare is a platform for sharing Camping Equipment",
      },
      {
        name: "keywords",
        content:
          "outdoor gear, camping, hiking, backpacking, rent, rental, share, sharing, adventure, travel, explore, outdoors, gear, equipment, tent, sleeping bag, camp, hammock, kayak, paddle, bike, bicycle, snowboard, ski, climbing, mountaineering, canoe, paddleboard, SUP, surfboard, surf, wetsuit, scuba, diving",
      },
      {
        name: "og:title",
        content: "Tenshare is a platform for sharing Camping Equipment",
      },
      {
        name: "og:description",
        content: "Tenshare is a platform for sharing Camping Equipment",
      },
    ],
  },
})
export default class App extends Vue {
  showAlert = false;
  locationName = "";

  logoutTimer: any;
  logoutTime = 900000; // 15 minutes in milliseconds - 600000

  config: AppConfig = config;

  userService = new UserService();
  itemService = new ItemService();
  authService = new AuthService();
  messageService = new MessageService();
  sportsService = new SportService();
  notificationService = new NotificationService();

  @Watch("$store.state.alert") onAlertChange() {
    this.showAlert = !this.showAlert;
  }

  @Watch("$store.state.loggedIn") loggedIn() {
    if (this.$store.state.loggedIn) {
      // Start the timer when the component is mounted
      this.startLogoutTimer();
      // Reset the timer on user activity (e.g., mousemove event)
      window.addEventListener("mousemove", this.resetLogoutTimer);
      // You can add more event listeners for other user activities as needed
    } else {
      window.removeEventListener("mousemove", this.resetLogoutTimer);
      clearTimeout(this.logoutTimer);
    }
  }

  startLogoutTimer() {
    this.logoutTimer = setTimeout(() => {
      this.logoutUser();
    }, this.logoutTime);
  }

  resetLogoutTimer() {
    clearTimeout(this.logoutTimer);
    this.startLogoutTimer();
  }

  logoutUser() {
    this.authService.signOut();
  }

  // async getLocationName(lat: number, lng: number): Promise<void> {
  //   const geocodingEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC1N2S7dDurbdNOIHCQ5NtHbzqx0OWS6Is`;
  //   try {
  //     const response = await fetch(geocodingEndpoint);
  //     const data = await response.json();
  //     const locationResults = data.results.map((result: any) => {
  //       return result;
  //     });
  //     const locationArray = locationResults.filter((item: any) => {
  //       return (
  //         item.types.includes("locality") || item.types.includes("postal_code")
  //       );
  //     });

  // this.$store.commit("SET_LOCATION", {
  //   lat: Number(locationArray[0].geometry.location.lat),
  //   lng: Number(locationArray[0].geometry.location.lng),
  //   name: locationArray[0].formatted_address,
  // });
  //     this.locationName = locationArray[0].formatted_address;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  getLocation(): void {
    HelperFunctions.getLocation()
      .then((location: any) => {
        this.$store.commit("SET_LOCATION_COORDINATES", {
          lat: Number(location.coords.latitude),
          lng: Number(location.coords.longitude),
        });
      })
      .catch((err) => {
        // If it errors setting london as the default location
        console.log(err, "error with getting location on app first load");
        this.$store.commit("SET_LOCATION_COORDINATES", {
          lat: 51.5072,
          lng: -0.1276,
        });
      });
  }

  mounted(): void {
    Sentry.captureException("This is an error in app.vue");
    this.sportsService.getAllSports();
    this.getLocation();
    onAuthStateChanged(auth, (user: User | null) => {
      if (user?.emailVerified) {
        this.$store.commit("SET_LOGGED_IN", true);
        this.$store.commit("SET_MASTER_USER", user);
      } else {
        this.$store.commit("SET_LOGGED_IN", false);
        this.$store.commit("SET_MASTER_USER", null);
      }

      if (this.$store.state.loggedIn) {
        if (user !== null && user.email) {
          this.userService.getUserByEmail(user.email);
        }
        this.notificationService.getAllNotifications(
          this.$store.state.user?.id,
          false
        );
        // }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        // this.$router.push("/").catch(() => {});
      }
    });
  }

  beforeDestroy() {
    // Clean up event listeners when the component is destroyed
    window.removeEventListener("mousemove", this.resetLogoutTimer);
    clearTimeout(this.logoutTimer);
  }
}
