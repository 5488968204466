export interface ThemeConfig {
  primaryColor: string;
  secondaryColor: string;
  // Add other styling properties as needed
}

export interface firebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface GoogleFontConfig {
  fontFamilies: string[];
}

export interface BannerConfig {
  bannerImage: string;
}

export interface BrandingConfig {
  appName: string;
  appLogoUrl: string;
  appEmail: string;
  googleFonts: GoogleFontConfig;
  styleSheet: string;
  banners: BannerConfig;
}

export interface ComponentsConfig {
  mainNav: {
    stripe_account_required: boolean;
  };
  homeView: {
    iconSection: {
      visible: boolean;
      images: string[];
    };
  };
  itemView: {
    category: {
      value: string;
    };
    festival_list: {
      visible: boolean;
    };
    camping_type: {
      visible: boolean;
    };
    listing_type: {
      visible: boolean;
    };
    pitching_service: {
      visible: boolean;
    };
    interests: {
      visible: boolean;
    };
  };
  textSearch: {
    search_by_tag: {
      visible: boolean;
    };
  };
  itemsView: {
    sub_category_filter: {
      visible: boolean;
    };
    favorites_text: string;
    filter_price_range: {
      visible: boolean;
    };
    filter_price: {
      visible: boolean;
    };
    filter_distance: {
      visible: boolean;
    };
    filter_category: {
      visible: boolean;
    };
    filter_festival: {
      visible: boolean;
    };
    filter_activity: {
      visible: boolean;
    };
  };
  // Add more component flags
}

export interface AppConfig {
  theme: ThemeConfig;
  branding: BrandingConfig;
  components: ComponentsConfig;
  firebaseConfig: firebaseConfig;
}

const config: AppConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyDHpu2f14Vjdcua8_W2AfQSpPJRTGSaOUU",
    authDomain: "tentshare-e25e1.firebaseapp.com",
    projectId: "tentshare-e25e1",
    // databaseURL: "https://tentshare-e25e1-default-rtdb.firebaseio.com",
    databaseURL:
      "https://tentshare-e25e1-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "tentshare-e25e1.appspot.com",
    messagingSenderId: "310388225881",
    appId: "1:310388225881:web:22043b45327977b4a2fe48",
    measurementId: "G-ZTH53TRJ0W",
    // apiKey: "AIzaSyC1N2S7dDurbdNOIHCQ5NtHbzqx0OWS6Is",
    // authDomain: "waste-not-13cef.firebaseapp.com",
    // projectId: "waste-not-13cef",
    // storageBucket: "waste-not-13cef.appspot.com",
    // databaseURL: "https://waste-not-13cef-default-rtdb.firebaseio.com",
    // messagingSenderId: "16992484597",
    // appId: "1:16992484597:web:b757da6d7d2fff60e319bc",
    // measurementId: "G-VE3EP6LESX",
  },
  theme: {
    primaryColor: "#FF5733",
    secondaryColor: "#4286f4",
  },
  branding: {
    appName: "tentshare",
    appEmail: "hello@tentshare.co.uk",
    appLogoUrl:
      "https://www.tentshare.co.uk/wp-content/uploads/2019/11/Tentshare-Long-logo-White.png",
    googleFonts: {
      fontFamilies: [
        "Montserrat",
        "Love+Ya+Like+A+Sister",
        "Roboto+Slab:100,300,400,700",
        "Open+Sans:400,700",
        "Roboto:100, 300, 400, 500, 700, 900",
      ],
    },
    styleSheet: "/style-1.css",
    banners: {
      bannerImage: "",
    },
  },
  components: {
    mainNav: {
      stripe_account_required: false,
    },
    homeView: {
      iconSection: {
        images: [],
        visible: true,
      },
    },
    textSearch: {
      search_by_tag: {
        visible: false,
      },
    },
    itemView: {
      category: {
        value: "tents",
      },
      festival_list: {
        visible: true,
      },
      camping_type: {
        visible: true,
      },
      listing_type: {
        visible: true,
      },
      pitching_service: {
        visible: true,
      },
      interests: {
        visible: false,
      },
    },
    itemsView: {
      sub_category_filter: {
        visible: true,
      },
      favorites_text: "Tentshare's Favorites",
      filter_price_range: {
        visible: true,
      },
      filter_price: {
        visible: true,
      },
      filter_category: {
        visible: true,
      },
      filter_festival: {
        visible: true,
      },
      filter_activity: {
        visible: true,
      },
      filter_distance: {
        visible: true,
      },
    },
    // Add more component flags
  },
};

export default config;
