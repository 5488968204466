import Vue from "vue";

export default Vue.extend({
  data(): {
    refreshing: boolean;
    registration: null | ServiceWorkerRegistration;
    updateExists: boolean;
  } {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },

  created(): void {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("swUpdated", () => {
      console.log("SW Event:", "swUpdated");
      this.updateAvailable;
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    updateAvailable(event: any): void {
      console.log("UPDATE available; please refresh.");
      this.registration = event.detail;
      this.updateExists = true;
    },

    refreshApp(): void {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
});
