import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Meta from "vue-meta";
import store from "../store/index";
import { onAuthStateChanged, getAuth } from "firebase/auth";

import config from "@/configs/config-tentshare";

Vue.use(Meta);
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => {
      if (config.branding.appName === "tentshare") {
        return import(
          /* webpackChunkName: "items view" */ "@/views/HomeView.vue"
        );
      } else {
        return import(/* webpackChunkName: "home" */ "../views/HomeView.vue");
      }
    },
  },
  {
    path: "/festival/:festivalName",
    name: "home",
    component: () => {
      if (config.branding.appName === "tentshare") {
        return import(
          /* webpackChunkName: "items view" */ "@/views/ItemsView.vue"
        );
      } else {
        return import(/* webpackChunkName: "home" */ "../views/HomeView.vue");
      }
    },
  },
  {
    path: "/olpro",
    name: "olpro listings",
    component: () =>
      import(/* webpackChunkName: "olpro" */ "@/views/ItemsView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/AdminView.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (store.getters.loggedIn) {
    //     next();
    //   } else {
    //     next("/login");
    //   }
    // },
  },
  {
    path: "/who-we-are",
    name: "who we are",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/how-it-works",
    name: "how it works",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/how-it-works-component.vue"
      ),
  },
  {
    path: "/stories",
    name: "stories",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/BlogView.vue"),
  },
  {
    path: "/contact-info-share",
    name: "contact info share",
    component: () =>
      import(
        /* webpackChunkName: "blog" */ "../views/ContactInfoShareView.vue"
      ),
  },
  {
    path: "/story/:title/:id",
    name: "story",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/BlogPostView.vue"),
  },
  {
    path: "/email-confirmation",
    name: "email confirmation",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmation" */ "../views/EmailConfirmationView.vue"
      ),
  },
  {
    path: "/confirm-email/__/auth/action",
    name: "confirm email",
    props: (route) => ({ query: route.query }), // Pass the query as props to the component
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */ "../views/ConfirmEmailView.vue"
      ),
  },
  {
    path: "/sign-up",
    name: "sign up",
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "../views/SignUpView.vue"),
  },
  {
    path: "/forgotten-password",
    name: "forgotten password",
    component: () =>
      import(
        /* webpackChunkName: "sign-up" */ "../views/ForgottenPasswordView.vue"
      ),
  },
  {
    path: "/create-new-user",
    name: "create new user",
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "../views/SignUpView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/user/:id",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/UserView.vue"),
  },
  {
    path: "/booking/:id",
    name: "booking",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      console.log(to.query);
      if (to.query.pay === "true") {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            next();
          } else {
            next("/login");
          }
        });
      }
      if (to.query.pay === "false") {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            next();
          } else {
            next("/login");
          }
        });
      }
      if (to.query.pay === "cancel") {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            next();
          } else {
            next("/login");
          }
        });
      }
      next();
    },
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookingView.vue"),
  },
  {
    path: "/booking-status/:id",
    name: "booking status",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    },
    component: () =>
      import(
        /* webpackChunkName: "booking" */ "../views/BookingStatusView.vue"
      ),
  },
  // {
  //   path: "/bookings/:id",
  //   name: "bookings",
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ "../views/BookingsView.vue"),
  // },
  {
    path: "/dashboard/:profileId",
    name: "dashboard",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/profile/:profileId",
    name: "profile",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
  },
  {
    path: "/item-upload/:uploadId",
    name: "item-upload",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    },
    component: () =>
      import(/* webpackChunkName: "item view" */ "../views/ItemView.vue"),
  },
  {
    // TODO: PUT UPLOAD ID IN HERE
    path: "/item-upload/festival/:festivalName/:listingType/:category",
    name: "item-upload",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    },
    component: () =>
      import(/* webpackChunkName: "item view" */ "../views/ItemView.vue"),
  },
  {
    path: "/inbox/",
    name: "inbox",
    beforeEnter: (to, from, next) => {
      store.commit("SET_INBOX_ROUTE", from);
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          // TODO: CAn we show a message here?
          next("/login");
        }
      });
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "inbox view" */ "../views/InboxView.vue"),
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () =>
      import(
        /* webpackChunkName: "analytics view" */ "../views/AnalyticsView.vue"
      ),
  },
  {
    path: "/item/:itemName/:itemId",
    name: "item",
    beforeEnter: (to, from, next) => {
      if (to.query.edit === "true") {
        const auth = getAuth();
        // if is owner then allow to edit
        // if (store.state.auth.user_id === store.state.item.owner) {
        //   console.log("were good to go");
        // }
        onAuthStateChanged(auth, (user) => {
          console.log(store.state, "store in router");
          if (user) {
            next();
          } else {
            next("/login");
          }
        });
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "item view" */ "../views/ItemView.vue"),
  },
  {
    path: "/listings",
    name: "listings",
    component: () =>
      import(/* webpackChunkName: "items view" */ "../views/ItemsView.vue"),
  },
  {
    path: "/success/:booking_id",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "success view" */ "../views/SuccessView.vue"),
  },
  {
    path: "/faqs/",
    name: "faqs",
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/QuestionsView.vue"),
  },
  {
    path: "/terms-of-service",
    name: "terms of service",
    component: () => import("../views/TermsView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "terms of service",
    component: () => import("../views/PolicyView.vue"),
  },
  {
    path: "/contact-us",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "*",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../components/generic/404-component.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// TODO: ONLY USE IF WE NEED TO UPDATE SIDEMAP ROUTES ARRAY
// function getRoutesList(routes: any, pre: any) {
//   return routes.reduce((array: any, route: any) => {
//     const path = `${pre}${route.path}`;

//     if (route.path !== "*") {
//       array.push(path);
//     }

//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`));
//     }

//     return array;
//   }, []);
// }

// getRoutesList(router.options.routes, "https://in-the-loop.co");

export default router;
